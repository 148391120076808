
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "IButton",
})
export default class IButton extends Vue {
  @Prop() on!: any;
  @Prop() minWidth!: number | string;
  @Prop() minHeight!: number | string;
  @Prop({ type: Boolean }) small!: boolean;
  @Prop({ type: Boolean }) icon!: boolean;
  @Prop({ type: Boolean }) block!: boolean;
  @Prop({ type: Boolean }) outlined!: boolean;
  @Prop({ type: Boolean }) depressed!: boolean;
  @Prop({ type: Boolean }) text!: boolean;
  @Prop({ type: String }) color!: string;
  @Prop({ type: Boolean }) disabled!: boolean;
  @Prop({ type: Boolean }) loading!: boolean;

  @Emit()
  public click() {
    return true;
  }
}
