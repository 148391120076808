
import { Component, Vue } from "vue-property-decorator";
import IButton from "@/ui-components/IButton/IButton.vue";
import { publicRouteNames } from "@/route-names/public";

@Component({
  components: {
    IButton
  }
})
export default class PageNotFound extends Vue {
  public home = publicRouteNames.HOME;

  redirectToHome() {
    this.$router.push({...this.home});
  }
}
